import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useApplication } from "@ryerson/frontend.application";
import { ContentSection } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { SearchInput } from "@ryerson/frontend.form-controls";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { renderRichText } from "@components/Shared/model/Contentful";
import { Media } from "@ryerson/frontend.layout";
import { GlossaryPageSetup } from "@components/Glossary/Page/Page";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import { ExpansionBox, Expander } from "@ryerson/frontend.expansion";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { Breadcrumb, Crumb } from "@ryerson/frontend.navigation";
import { MakeHighlights } from "@components/Glossary/Page/Page";
import { navigate } from "gatsby";
import { BreadcrumbProps } from "@ryerson/frontend.navigation/dist/breadcrumb/Breadcrumb";
import { sanitizeInput } from "@components/Utilities/Utilities";

export type FAQHero = {
	title: string;
	mobileTitle: string;
	searchPlaceholder: string;
	description: string;
};

export type FAQPageProps = {
	content: GlossaryPageSetup;
	hero: FAQHero;
};

type FAQGroupItem = {
	label: string;
	query: string;
};

const FAQGroups: LanguageContent<FAQGroupItem[]> = {
	en: [
		{
			label: "Sign Up for an account",
			query: "Sign Up for an account",
		},
		{
			label: "Forgot your password?",
			query: "Forgot your password?",
		},
		{
			label: "Customize your account",
			query: "Customize your account",
		},
		{
			label: "Looking for Metal?",
			query: "Looking for Metal?",
		},
		{
			label: "Custom cut metal",
			query: "Custom cut metal",
		},
		{
			label: "Create a Shopping List",
			query: "Create a Shopping List",
		},
		{
			label: "Convert your quote to an order",
			query: "Convert your quote to an order",
		},
		{
			label: "Upload your metal needs",
			query: "Upload your metal needs",
		},
		{
			label: "Placing your order",
			query: "Placing your order",
		},
		{
			label: "I placed my order, what's next?",
			query: "I placed my order, what's next?",
		},
		{
			label: "Barcode Scanning",
			query: "Barcode Scanning",
		},
		{
			label: "Market Intelligence: The Gauge",
			query: "Market Intelligence: The Gauge",
		},
		{
			label: "Ryerson Stocklist",
			query: "Ryerson Stocklist",
		},
		{
			label: "Career Opportunities",
			query: "Career Opportunities",
		},
		{
			label: "Resources",
			query: "Resources",
		},
		{
			label: "Investor Relations",
			query: "Investor Relations",
		},
		{
			label: "Need More Help?",
			query: "Need More Help?",
		},
		{
			label: "Additional Information",
			query: "Additional Information",
		},
	],
	fr: [
		{
			label: "Créer un compte",
			query: "Sign Up for an account",
		},
		{
			label: "Mot de passe oublié?",
			query: "Forgot your password?",
		},
		{
			label: "Personnalisez votre compte",
			query: "Customize your account",
		},
		{
			label: "Vous cherchez du métal?",
			query: "Looking for Metal?",
		},
		{
			label: "Métal découpé sur mesure",
			query: "Custom cut metal",
		},
		{
			label: "Créer une liste de courses",
			query: "Create a Shopping List",
		},
		{
			label: "Transformez votre devis en commande",
			query: "Convert your quote to an order",
		},
		{
			label: "Téléchargez vos besoins en métal",
			query: "Upload your metal needs",
		},
		{
			label: "Passer votre commande",
			query: "Placing your order",
		},
		{
			label: "J'ai passé ma commande, quelle est la suite?",
			query: "I placed my order, what's next?",
		},
		{
			label: "Numérisation de codes-barres",
			query: "Barcode Scanning",
		},
		{
			label: "Intelligence du marché : la jauge",
			query: "Market Intelligence: The Gauge",
		},
		{
			label: "Liste de stock de Ryerson",
			query: "Ryerson Stocklist",
		},
		{
			label: "Opportunités de carrière",
			query: "Career Opportunities",
		},
		{
			label: "Ressources",
			query: "Resources",
		},
		{
			label: "Relations avec les investisseurs",
			query: "Investor Relations",
		},
		{
			label: "Besoin d'aide?",
			query: "Need More Help?",
		},
		{
			label: "Informations Complémentaires",
			query: "Additional Information",
		},
	],
	es: [
		{
			label: "Regístrate para una cuenta",
			query: "Sign Up for an account",
		},
		{
			label: "¿Olvidaste tu contraseña?",
			query: "Forgot your password?",
		},
		{
			label: "Personaliza tu cuenta",
			query: "Customize your account",
		},
		{
			label: "¿Buscas Metal?",
			query: "Looking for Metal?",
		},
		{
			label: "Metal cortado a la medida",
			query: "Custom cut metal",
		},
		{
			label: "Crear una lista de compras",
			query: "Create a Shopping List",
		},
		{
			label: "Convierta su cotización en un pedido",
			query: "Convert your quote to an order",
		},
		{
			label: "Sube tus necesidades de metal",
			query: "Upload your metal needs",
		},
		{
			label: "Passer votre commande",
			query: "Placing your order",
		},
		{
			label: "Hice mi pedido, ¿qué sigue?",
			query: "I placed my order, what's next?",
		},
		{
			label: "Escaneo de código de barras",
			query: "Barcode Scanning",
		},
		{
			label: "Inteligencia de mercado: el indicador",
			query: "Market Intelligence: The Gauge",
		},
		{
			label: "Lista de existencias de Ryerson",
			query: "Ryerson Stocklist",
		},
		{
			label: "Oportunidades profesionales",
			query: "Career Opportunities",
		},
		{
			label: "Recursos",
			query: "Resources",
		},
		{
			label: "Relaciones con inversionistas",
			query: "Investor Relations",
		},
		{
			label: "¿Necesitas más ayuda?",
			query: "Need More Help?",
		},
		{
			label: "Información Adicional",
			query: "Additional Information",
		},
	],
};

type ContentfulNode = {
	slug: string;
	title: string;
	category: string;
	highlightedTitle?: any;
	highlightedDetail?: boolean;
	detail: any;
};

const FaqCustomBreadCrumb: Crumb[] = [
	{ label: "Home", url: "/" },
	{ label: "Metal Resources", url: "/metal-resources" },
	{ label: "FAQ", url: "/metal-resources/references/faq" },
];

const FAQBreadcrumb: React.FC = () => {
	const FAQBreadcrumb: BreadcrumbProps = {
		data: FaqCustomBreadCrumb,
		type: "tertiary",
		size: "xs",
	};
	return <Breadcrumb {...FAQBreadcrumb} />;
};

const Left = styled.div`
	display: inline-block;
	width: 260px;
	height: auto;
	vertical-align: top;
`;

const ContactPanel = styled.div`
	width: 100%;
	height: auto;
	padding: 20px 20px 25px 20px;
	margin-top: 10px;
	border-radius: 2px;
`;

const Filters = styled.div`
	display: block;
	width: 100%;
	height: auto;
	border-radius: 2px;
	padding-bottom: 10px;
`;

const Filter = styled.div`
	display: block;
	width: 100%;
	padding-left: 20px;
	box-sizing: border-box;
	min-height: 40px;
	cursor: pointer;
`;

const Right = styled.div`
	display: inline-block;
	width: calc(100% - 270px);
	height: auto;
	margin-left: 10px;
	margin-bottom: 140px;
	padding: 40px;
	border-radius: 2px;
`;

const AccordionContainer = styled.div`
	display: block;
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
	height: auto;
`;

const MobileSpacer = styled.div`
	display: block;
	width: 100%;
	height: 35px;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 10px;
`;

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const HeroLeft = styled.div`
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 320px);
	height: auto;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				display: block;
				width: 100%;
				height: auto;
			}
		`;
	}}
`;

const HeroRight = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 320px;
	padding-top: 30px;
	height: auto;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				display: block;
				width: 100%;
				height: auto;
				margin-bottom: 15px;
			}
		`;
	}}
`;

const GlossaryPage: React.FC<FAQPageProps> = ({ content, hero }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const queryData = useStaticQuery(graphql`
		query FAQQuery {
			allContentfulFrequentlyAskedQuestion(sort: { fields: title }) {
				edges {
					node {
						slug
						title
						category
						company
						detail {
							raw
							references {
								contentful_id
								title
								file {
									fileName
									url
								}
							}
						}
						node_locale
					}
				}
			}
		}
	`);

	let FAQItems: ContentfulNode[] = [];
	const company =
		process.env.GATSBY_COMPANY === "southernToolSteel" ? "Southern Tool Steel" : "Ryerson";
	queryData.allContentfulFrequentlyAskedQuestion.edges.forEach((nodes: any) => {
		if (
			nodes.node.node_locale.split("-").shift() === language &&
			nodes.node.company.indexOf(company) !== -1
		) {
			FAQItems.push(nodes.node);
		}
	});

	React.useEffect(() => {
		let newFAQItems: ContentfulNode[] = [];
		queryData.allContentfulFrequentlyAskedQuestion.edges.forEach((nodes: any) => {
			if (
				nodes.node.node_locale.split("-").shift() === language &&
				nodes.node.company.indexOf(company) !== -1
			) {
				newFAQItems.push(nodes.node);
			}
		});
		setCurrentItems(newFAQItems);
		setCurrentGroups(FAQGroups[language]);
	}, [language]);

	const [currentGroup, setCurrentGroup] = React.useState<string>("");
	const [currentItems, setCurrentItems] = React.useState<ContentfulNode[]>(FAQItems);
	const [currentGroups, setCurrentGroups] = React.useState<any[]>(FAQGroups[language]);
	const [searchTerm, setSearchTerm] = React.useState<string>("");
	React.useEffect(() => {
		if (currentGroup.length > 0) {
			setSearchTerm("");
			let newItems: ContentfulNode[] = [];
			let newGroups: any[] = [];
			FAQGroups[language].forEach((group: any) => {
				if (group.query === currentGroup) {
					newGroups.push(group);
				}
			});
			FAQItems.forEach((item: ContentfulNode, index: number) => {
				if (currentGroup === item.category) newItems.push(item);
			});
			setCurrentGroups(newGroups);
			setCurrentItems(newItems);
		} else {
			setCurrentItems(FAQItems);
			setCurrentGroups(FAQGroups[language]);
		}
	}, [currentGroup]);

	const recursiveLook = (obj: any, term: string): boolean => {
		if (obj.content) {
			if (obj.content.length > 0) {
				for (let i = 0; i < obj.content.length; i++) {
					let child = obj.content[i];
					if (child.content) {
						return recursiveLook(child, term);
					} else if (child.value && child.value.length > 0) {
						return child.value.toLowerCase().includes(term.toLowerCase());
					} else {
						return false;
					}
				}
			} else {
				return false;
			}
		} else {
			return false;
		}
		return false;
	};

	React.useEffect(() => {
		if (searchTerm.length > 0) {
			setCurrentGroup("");
			if (searchTerm.length > 2) {
				let filteredItems: ContentfulNode[] = [];
				let filteredGroups: any[] = [];
				FAQItems.forEach((item: ContentfulNode, index: number) => {
					let mutableItem = Object.create(item);
					if (mutableItem.title.toLowerCase().includes(searchTerm.toLowerCase())) {
						if (item.title.toLowerCase().includes(searchTerm.toLowerCase())) {
							let re = new RegExp(searchTerm, "gi");
							let match = mutableItem.title.match(re);
							let newTitle = mutableItem.title;
							if (match && match.length > 0)
								newTitle = newTitle.replaceAll(match[0], "|" + match[0] + "|");
							mutableItem.highlightedTitle = newTitle;
						}
						filteredItems.push(mutableItem);
					} else {
						if (recursiveLook(JSON.parse(item.detail.raw), searchTerm)) {
							filteredItems.push(item);
						}
					}
				});
				if (filteredItems.length > 0) {
					FAQGroups[language].forEach((group: any, index: number) => {
						let addToGroup = false;
						filteredItems.forEach((item: ContentfulNode, idx: number) => {
							if (group.query === item.category) {
								addToGroup = true;
							}
							if (idx === filteredItems.length - 1) {
								if (addToGroup === true) {
									filteredGroups.push(FAQGroups[language][index]);
								}
							}
						});
						if (FAQGroups[language].length - 1 === index) {
							setCurrentGroups(filteredGroups);
							setCurrentItems(filteredItems);
						}
					});
				} else {
					setCurrentItems([]);
					setCurrentGroups([]);
				}
			} else {
				setCurrentItems(FAQItems);
				setCurrentGroups(FAQGroups[language]);
			}
		} else {
			setCurrentItems(FAQItems);
			setCurrentGroups(FAQGroups[language]);
		}
	}, [searchTerm]);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						{company === "Ryerson" ? (
							<Breadcrumb type="tertiary" size="xs" />
						) : (
							<FAQBreadcrumb />
						)}
					</BreadcrumbContainer>
					<HeroLeft theme={theme}>
						<Typography
							css={css`
								margin-top: 20px;
								margin-bottom: 25px;
							`}
							variant="h1"
							type="tertiary"
						>
							{hero.title}
						</Typography>
						<Typography variant="p" size="md" type="tertiary">
							{hero.description}
						</Typography>
					</HeroLeft>
					<HeroRight theme={theme}>
						<SearchInput
							shape="rounded"
							placeholder={hero.searchPlaceholder}
							size="md"
							language={language}
							label=""
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(sanitizeInput(e.target.value, 50));
							}}
						/>
					</HeroRight>
				</ContentSection>
				<ContentSection type="tertiary" vPadding="0">
					<Left>
						<Filters
							css={css`
								background-color: ${theme.colors.secondary.background};
							`}
						>
							{FAQGroups[language].map((group: any, index: number) => {
								return (
									<Filter
										key={index}
										onClick={() => {
											group.query === currentGroup
												? setCurrentGroup("")
												: setCurrentGroup(group.query);
										}}
										css={css`
											background-color: ${group.query === currentGroup
												? theme.colors.primary.white
												: "transparent"};
										`}
									>
										<Typography
											color={
												group.query === currentGroup
													? theme.colors.primary.darkerGray
													: theme.colors.primary.link
											}
											variant="div"
											size="md"
											weight="bold"
											css={css`
												padding-top: 9px;
												padding-bottom: 9px;
											`}
										>
											{group.label}
										</Typography>
									</Filter>
								);
							})}
						</Filters>
						<ContactPanel
							css={css`
								background-color: ${theme.colors.primary.darkGray};
							`}
						>
							<Typography
								weight="bold"
								variant="h4"
								css={css`
									margin-top: 0px;
									margin-bottom: 30px;
									font-size: 20px;
									letter-spacing: -1px;
								`}
								type="tertiary"
							>
								{content.contactLabel}
							</Typography>
							<Button
								onClick={() => {
									navigate("/contact-us");
								}}
								size="sm"
								fullwidth={true}
								type="secondary"
								css={css`
									margin-bottom: 15px;
								`}
								shape="rounded"
								label={content.contactButton}
							/>
							<Button
								onClick={() => {
									if (window && (window as any).LiveChatWidget)
										(window as any).LiveChatWidget.call("maximize");
								}}
								size="sm"
								type="secondary"
								fullwidth={true}
								shape="rounded"
								label={content.contactChat}
							/>
						</ContactPanel>
					</Left>
					<Right
						css={css`
							background-color: ${theme.colors.primary.background};
						`}
					>
						{currentGroups.map((group: any, index: number) => {
							return (
								<div
									key={index}
									css={css`
										display: ${currentGroup === group.query ||
										currentGroup === ""
											? "block"
											: "none"};
									`}
								>
									<Typography
										type="primary"
										css={css`
											margin-top: 0px;
											margin-bottom: 20px;
										`}
										variant="h4"
									>
										{group.label}
									</Typography>
									<>
										{currentItems.map((item: ContentfulNode, index: number) => {
											return (
												<React.Fragment key={index}>
													{group.query === item.category ? (
														<>
															<ExpansionBox
																headerType="secondary"
																bodyType="primary"
																clickableHeader={true}
																defaultOpen={
																	item.highlightedDetail
																		? true
																		: false
																}
																header={
																	<Typography
																		variant="div"
																		type="secondary"
																		color={
																			theme.colors.secondary
																				.header
																		}
																	>
																		{item.highlightedTitle &&
																		item.highlightedTitle
																			.length > 0
																			? MakeHighlights(
																					item.highlightedTitle
																			  )
																			: item.title}
																	</Typography>
																}
															>
																<Expander>
																	<Typography
																		variant="div"
																		size="md"
																		type="primary"
																		css={css`
																			padding: 32px;
																			box-sizing: border-box;
																			img {
																				max-width: 100%;
																			}
																		`}
																	>
																		{renderRichText(
																			item.detail.raw,
																			"primary",
																			item.detail.references,
																			searchTerm
																		)}
																	</Typography>
																</Expander>
															</ExpansionBox>
															<Spacer />
														</>
													) : (
														<></>
													)}
												</React.Fragment>
											);
										})}
									</>
									<Spacer />
									<Spacer />
									<Spacer />
								</div>
							);
						})}
					</Right>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						{company === "Ryerson" ? (
							<Breadcrumb type="tertiary" size="xs" />
						) : (
							<FAQBreadcrumb />
						)}
					</BreadcrumbContainer>
					<HeroLeft theme={theme}>
						<Typography
							css={css`
								margin-top: 20px;
								margin-bottom: -10px;
							`}
							variant="h1"
							type="tertiary"
						>
							{hero.mobileTitle}
						</Typography>
					</HeroLeft>
					<HeroRight theme={theme}>
						<SearchInput
							shape="rounded"
							placeholder={hero.searchPlaceholder}
							size="md"
							language={language}
							label=""
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(e.target.value);
							}}
						/>
					</HeroRight>
				</ContentSection>
				<ContentSection type="primary" hPadding="0px" vPadding="0px">
					<AccordionContainer>
						<Accordion type="primary" multiple={true}>
							{currentGroups.map((group: any, index: number) => {
								return (
									<AccordionTab
										circled={true}
										header={group.label}
										maxHeight="5000px"
										multiple={true}
										key={index}
										defaultOpen={false}
									>
										<MobileSpacer />
										<>
											{currentItems.map(
												(item: ContentfulNode, index: number) => {
													return (
														<React.Fragment key={index}>
															{group.query === item.category ? (
																<>
																	<ExpansionBox
																		headerType="secondary"
																		bodyType="primary"
																		clickableHeader={true}
																		header={
																			<Typography
																				variant="div"
																				type="secondary"
																				size="md"
																				color={
																					theme.colors
																						.secondary
																						.header
																				}
																			>
																				{item.highlightedTitle &&
																				item
																					.highlightedTitle
																					.length > 0
																					? MakeHighlights(
																							item.highlightedTitle
																					  )
																					: item.title}
																			</Typography>
																		}
																	>
																		<Expander>
																			<Typography
																				variant="div"
																				size="md"
																				type="primary"
																				css={css`
																					padding-top: 10px;
																					padding-bottom: 10px;
																					padding-left: 18px;
																					padding-right: 18px;
																					box-sizing: border-box;
																					img {
																						max-width: 100%;
																					}
																				`}
																			>
																				{renderRichText(
																					item.detail.raw,
																					"primary",
																					item.detail
																						.references,
																					searchTerm
																				)}
																			</Typography>
																		</Expander>
																	</ExpansionBox>
																	<Spacer />
																</>
															) : (
																<></>
															)}
														</React.Fragment>
													);
												}
											)}
											<Spacer />
											<Spacer />
											<Spacer />
											<Spacer />
										</>
									</AccordionTab>
								);
							})}
						</Accordion>
					</AccordionContainer>
					<ContactPanel
						css={css`
							background-color: ${theme.colors.primary.darkGray};
							display: block;
							width: 100%;
							margin-top: 30px;
							padding: 20px 20px 65px 20px;
						`}
					>
						<Typography
							weight="bold"
							variant="h4"
							css={css`
								margin-top: 0px;
								margin-bottom: 30px;
								font-size: 20px;
								letter-spacing: -1px;
							`}
							type="tertiary"
						>
							{content.contactLabel}
						</Typography>
						<Button
							onClick={() => {
								navigate("/contact-us");
							}}
							size="sm"
							fullwidth={true}
							type="secondary"
							css={css`
								margin-bottom: 15px;
							`}
							shape="rounded"
							label={content.contactButton}
						/>
						<Button
							onClick={() => {
								if (window && (window as any).LiveChatWidget)
									(window as any).LiveChatWidget.call("maximize");
							}}
							size="sm"
							type="secondary"
							fullwidth={true}
							shape="rounded"
							label={content.contactChat}
						/>
					</ContactPanel>
				</ContentSection>
			</Media>
		</>
	);
};

export default GlossaryPage;
